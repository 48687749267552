<template>
  <div :class="['block', !block.isValid ? 'block--error' : '']">
    <div class="block__top">
      <h2 class="block-title" v-if="block.title">
        {{ block.title }}
        <button
          v-if="block.description"
          @click="openContentModal"
          class="btn-help"
          :data-html="block.description"
        ></button>
      </h2>
    </div>
    <div class="block__main block__main--options">
      <ul :class="['option-list', block.listClass]">
        <li
          v-for="option in block.options"
          :key="option.key"
          class='list-item'
        >
        <!-- :class="['list-item', ...option.classes]" -->
          <input
            type="radio"
            :checked="option.selected"
            :id="option.id"
            :value="option.key"
            :name="block.name"
            :disabled="isLoading"
            @change="onInputChange($event, block, option)"
          />
          <label :for="option.id"></label>
          <div class="list-item__image">
            <div class="image-wrapper">
              <img
                :src="option.data.thumbnail"
                alt=""
                @load="
                  (event) => {
                    event.target.classList.add('has-loaded');
                  }
                "
              />
            </div>
          </div>
          <div class="list-item__content">
            <div class="list-item-title" v-if="option.data.title">
              <span v-html="option.data.title"></span>
              <button
                v-if="option.data.image"
                @click="openContentModal"
                class="btn-magnify"
                :data-image="option.data.image"
              ></button>
            </div>
            <div class="list-item-video" v-if="option.data.video">
              <a
                @click="openContentModal"
                class="btn-video"
                :data-video="option.data.video"
              >
                Bekijk de video
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="block__messages" v-if="block.messages.length > 0">
      <ul>
        <li
          v-for="message in block.messages"
          :class="message.className"
          :key="message.blockElementId"
        >
          {{ message.text }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemSelect",
  props: {
      block: Object,
      isLoading: Boolean,
  },
  methods: {
      openContentModal(event) {
          this.$store.commit("openContentModal", event)
      },
      onInputChange(event, block, option) {
          this.$emit("onInputChange", event, block, option)
      }
  }
};
</script>