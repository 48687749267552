import { createApp } from "vue";
import App from './App.vue';
import router from './router';
import store from './store'
import './registerServiceWorker'

const app = createApp(App)

app.config.productionTip = false;
app.config.devtools = true;

app.use(router)
app.use(store)
app.mount("#app");
