<template>
  <transition
    mode="in-out"
    :name="currentStep >= lastStep ? 'next-slide' : 'prev-slide'"
    :duration="{ enter: 800, leave: 400 }"
  >
    <div class="blocks-wrapper" v-if="!isUpdatingStep">
      <template v-for="(block, index) in content.blocks">
        <!-- Item type: Block -->
        <Block v-if="block.itemType === 'Block'" :key="index + block.itemType" :block="block" />

        <!-- Item type: ListProperty -->
        <ItemSelect
          v-else-if="block.itemType === 'ListProperty'"
          :key="index + block.itemType"
          :block="block"
          :isLoading="isLoading"
          @onInputChange="onInputChange"
        />

        <!-- Item type: RoomSelector -->
        <RoomSelect
          v-else-if="block.itemType === 'RoomSelector'"
          :key="index + block.itemType"
          :block="block"
          :isLoading="isLoading"
          @onRoomChange="onRoomChange"
        />

        <!-- Item type: Room Color Selector -->
        <RoomColor
          v-else-if="block.itemType === 'RoomColorSelector' && !product.roomURL"
          :key="index + block.itemType"
          :block="block"
          :isLoading="isLoading"
          @onRoomColorChange="onRoomColorChange"
        />

        <!-- Item type: Room Color Selector for DesignSpace API -->
        <RoomColorDesignspace
          v-else-if="block.itemType === 'RoomColorSelector' && product.roomURL"
          :key="index + block.itemType"
          :block="block"
          :isLoading="isLoading"
          @onRoomColorChange="onRoomColorChange"
        />

        <!-- Item type: SummaryBlock -->
        <ProductSummary
          v-else-if="block.itemType === 'SummaryBlock'"
          :key="index + block.itemType"
          :block="block"
        />

        <!-- Item type: SumaryPriceBlock -->
        <TotalPrice
          v-else-if="block.itemType === 'SummaryPriceBlock'"
          :key="index + block.itemType"
          :block="block"
          :price="price"
        />

        <!-- Item type: BooleanProperty -->
        <CheckboxOption
          v-else-if="block.itemType === 'BooleanProperty'"
          :key="index + block.itemType"
          :block="block"
          :isLoading="isLoading"
          @onInputChange="onInputChange"
        />

        <!-- Item type: Integer Property -->
        <NumberInput
          v-else-if="block.itemType === 'IntegerProperty'"
          :key="index + block.itemType"
          :block="block"
          :isLoading="isLoading"
          @onInputChange="onInputChange"
        />
      </template>
    </div>
  </transition>
</template>

<script>
// Components
import Block from "./configurator-sections/Block.vue";
import ItemSelect from "./configurator-sections/ItemSelect.vue";
import RoomSelect from "./configurator-sections/RoomSelect.vue";
import RoomColor from "./configurator-sections/RoomColor.vue";
import RoomColorDesignspace from "./configurator-sections/RoomColorDesignspace.vue";
import ProductSummary from "./configurator-sections/ProductSummary.vue";
import TotalPrice from "./configurator-sections/TotalPrice.vue";
import CheckboxOption from "./configurator-sections/CheckboxOption.vue";
import NumberInput from "./configurator-sections/NumberInput.vue";

export default {
  name: "Configurator",
  components: {
    Block,
    ItemSelect,
    RoomSelect,
    RoomColor,
    RoomColorDesignspace,
    ProductSummary,
    TotalPrice,
    CheckboxOption,
    NumberInput,
  },
  props: {
    isLoading: Boolean,
    isLoadingFirstTime: Boolean,
    isUpdatingStep: Boolean,
    product: Object,
    productData: Object,
    currentStep: Number,
    lastStep: Number,
    totalSteps: Number,
    content: Object,
    price: String,
  },
  methods: {
    onInputChange(event, block, option) {
      this.$emit("onInputChange", event, block, option);
    },
    onRoomChange(event, id) {
      this.$emit("onRoomChange", event, id);
    },
    onRoomColorChange(color) {
      this.$emit("onRoomColorChange", color);
    },
    prevStep() {
      this.$emit("prevStep");
    },
    nextStep() {
      this.$emit("nextStep");
    },
    submitMailForm() {
      this.$emit("submitMailForm");
    },
  },
};
</script>