<template>
  <div class="block">
    <div class="block__main">
      <div class="price" v-if="price">
        <span class="price__label">{{ staticContent.priceLabel }}</span>
        <span class="price__amount">&euro; {{ price }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "TotalPrice",
  props: {
      block: Object,
      price: String
  },
  computed: {
      ...mapGetters(["staticContent"]),
  }
};
</script>