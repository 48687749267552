<template>
  <div class="mail-form custom-form">
    <form @submit.prevent="submitMailForm">
      <ul>
        <li class="mail-form__label">
          <label for="mail-form-input">{{ staticContent.emailLabel }}</label>
        </li>
        <li class="mail-form__input">
          <input
            type="email"
            id="mail-form-input"
            :placeholder="staticContent.emailPlaceholder"
            v-model="emailAddress"
          />
          <button class="btn btn--1 btn--icon" type="submit">
            <span class="icon icon-email">
              <EmailIcon />
            </span>
            {{ staticContent.emailButton }}
          </button>
        </li>
      </ul>
    </form>
  </div>
</template>

<script>
// Vue / Vuex
import { mapGetters } from "vuex";

// Icons
import EmailIcon from "../../icons/EmailIcon.vue";

export default {
  name: "EmailForm",
  components: {
    EmailIcon,
  },
  data() {
    return {
      emailAddress: "",
    };
  },
  computed: {
    ...mapGetters(["staticContent"]),
  },
  methods: {
    submitMailForm() {
      this.$emit("submitMailForm", this.emailAddress)
    },
  },
};
</script>