import {createStore} from 'vuex'
import configurator from './modules/configurator'
import productRender from './modules/productRender'
import email from './modules/email'
import loadingState from './modules/loadingState'
import modal from './modules/modal'
import session from './modules/session'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
    modules: {
        configurator,
        productRender,
        email,
        loadingState,
        modal,
        session
    },
    strict: debug,
    devtools: debug
})
