<template>
  <a
    :href="$route.query.back_url"
    class="btn btn--5 btn--prev btn--back-btn"
    v-if="$route.query.back_url"
  >
    <BackIcon />
    {{
      this.$route.query.back_label ? this.$route.query.back_label : "Ga terug"
    }}
  </a>
</template>

<script>
import BackIcon from "../../icons/BackIcon.vue";

export default {
  name: "PreviousPage",
  components: {
    BackIcon,
  },
};
</script>