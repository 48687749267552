<template>
  <div
    :class="['panel', selectorPanelIsOpen ? 'is-open' : 'is-closed']"
    v-if="product"
  >
    <transition name="fade" duration="400">
      <Loader
        v-if="isLoading || isUpdatingStep"
        :loaderText="isLoadingFirstTime ? currentLoadingtext : loadingText"
      />
    </transition>
    <div
      :class="[
        'panel__main',
        isLoading && !isUpdatingStep && productData ? 'is-loading' : '',
      ]"
    >
      <div class="select-product" v-if="!mailResponse">
        <Configurator
          :isLoading="isLoading"
          :isLoadingFirstTime="isLoadingFirstTime"
          :isUpdatingStep="isUpdatingStep"
          :product="product"
          :productData="productData"
          :currentStep="currentStep"
          :lastStep="lastStep"
          :totalSteps="totalSteps"
          :content="content"
          :price="price"
          @onInputChange="onInputChange"
          @onRoomChange="onRoomChange"
          @onRoomColorChange="onRoomColorChange"
          @prevStep="prevStep"
          @nextStep="nextStep"
        />
      </div>
      <div v-else>
        <EmailResponse
          @reset="reset"
        />
      </div>
    </div>
    <PanelFooter
      v-if="productData && mailResponse === false"
      :productData="productData"
      :price="price"
      :isLoading="isLoading"
      :currentStep="currentStep"
      :totalSteps="totalSteps"
      @submitMailForm="submitMailForm"
      @previousStep="prevStep"
      @nextStep="nextStep"
      @reset="reset"
    />
    <ExpandPanelButton @toggleSelectorPanel="toggleSelectorPanel" />
  </div>
</template>

<script>
// VueX store
import { mapGetters } from 'vuex'

// Components
import Configurator from "../configurator/Configurator.vue";
import EmailResponse from "../configurator/EmailResponse.vue";
import PanelFooter from "../configurator/PanelFooter.vue";
import Loader from "../ui/Loader.vue";
import ExpandPanelButton from "../ui/ExpandPanelButton.vue";

export default {
  name: "ConfiguratorPanel",
  components: {
    Configurator,
    EmailResponse,
    PanelFooter,
    Loader,
    ExpandPanelButton,
  },
  props: {
    productData: Object,
    currentStep: Number,
    lastStep: Number,
    totalSteps: Number,
    content: Object,
    price: String,
  },
  data() {
    return {
      selectorPanelIsOpen: true,
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "isLoadingFirstTime",
      "isUpdatingStep",
      "currentLoadingtext",
      "loadingText",
      "product",
      "mailResponse"
    ])
  },
  methods: {
    toggleSelectorPanel() {
      this.selectorPanelIsOpen = !this.selectorPanelIsOpen;
    },
    onInputChange(event, block, option) {
      this.$emit("onInputChange", event, block, option);
    },
    onRoomChange(event, id) {
      this.$emit("onRoomChange", event, id);
    },
    onRoomColorChange(color) {
      this.$emit("onRoomColorChange", color);
    },
    prevStep() {
      this.$emit("prevStep");
    },
    nextStep() {
      this.$emit("nextStep");
    },
    reset() {
      this.$emit("reset");
    },
    submitMailForm(emailAddress) {
      this.$emit("submitMailForm", emailAddress)
    }
  },
};
</script>