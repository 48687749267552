<template>
  <div class="loader">
    <div class="loader-animation"></div>
    <h2 class="loader-text" v-if="loaderText">{{ loaderText }}</h2>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
      loaderText: {
          type: String,
          required: false
      }
  },
};
</script>