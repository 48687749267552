<template>
  <SingleModal :showModal="!slug || subProducts" customClass="product-selector">
    <template v-slot:modal-header>
      <h1>{{ staticContent.productSelect }}</h1>
      <button class="close-btn" @click="hideSubProducts" v-if="subProducts">
       <CloseModalIcon />
      </button>
    </template>
    <template v-slot:modal-body>
      <Loader v-if="!initialProductData" />
      <ul
        class="products"
        v-if="initialProductData"
        :class="{ 'is-hidden': subProducts }"
      >
        <li
          class="product"
          v-for="product in topLevelProducts"
          :key="product.ID"
        >
          <router-link
            tag="a"
            :to="'/product/' + product.slug"
            v-if="!product.sub"
          >
            <h3 v-html="product.productTitle"></h3>
            <img
              :src="product.productImg"
              :width="product.productImgWidth"
              :height="product.productImgHeight"
            />
          </router-link>
          <a role="button" v-else @click="showSubProducts(product.ID)">
            <h3 v-html="product.productTitle"></h3>
            <img
              :src="product.productImg"
              :width="product.productImgWidth"
              :height="product.productImgHeight"
            />
          </a>
        </li>
      </ul>
      <transition name="fade">
        <div class="sub-products__container" v-if="subProducts">
          <ul class="sub-products">
            <li v-for="product in subProducts" :key="product.id">
              <div v-if="product.slug !== $route.params.slug">
                <router-link
                  tag="a"
                  :to="'/product/' + product.slug"
                  v-if="!product.sub"
                  @click="
                    subProductClicked($event, '/product/' + product.slug)
                  "
                >
                  <h3 v-html="product.productTitle"></h3>
                  <img
                    :src="product.productImg"
                    :width="product.productImgWidth"
                    :height="product.productImgHeight"
                  />
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </transition>
    </template>
  </SingleModal>
</template>

<script>
// Vuex store
import { mapGetters } from "vuex"

// Components
import SingleModal from "../components/ui/SingleModal.vue";
import Loader from "../components/ui/Loader.vue";

// Icons
import CloseModalIcon from '../icons/CloseModalIcon.vue'

export default {
  name: "SelectProduct",
  components: {
    SingleModal,
    Loader,
    CloseModalIcon
  },
  methods: {
    hideSubProducts() {
      this.$store.commit("hideSubProducts");
    },
    showSubProducts(id) {
      this.$store.commit("showSubProducts", id);
    },
    subProductClicked() {
      this.$emit("subProductClicked")
    }
  },
  computed: {
    ...mapGetters([
      "staticContent",
      "initialProductData",
      "slug",
      "product",
      "topLevelProducts",
      "subProducts",
    ]),
  },
};
</script>