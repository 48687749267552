<template>
  <svg
    class="icon icon--open"
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="26"
    viewBox="0 0 37 26"
  >
    <g>
      <path
        d="M18.5,25.69A12.69,12.69,0,1,1,31.27,13,12.74,12.74,0,0,1,18.5,25.69"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        d="M18.5,0A13,13,0,1,0,31.57,13,13,13,0,0,0,18.5,0m0,.61A12.39,12.39,0,1,1,6,13,12.44,12.44,0,0,1,18.5.61"
        fill-rule="evenodd"
      />
      <path
        d="M.61,13.31H8c0-.11,0-.21,0-.31s0-.2,0-.31H.61Z"
        fill-rule="evenodd"
      />
      <path
        d="M29.05,13.31h7.34v-.62H29.05c0,.11,0,.21,0,.31s0,.2,0,.31"
        fill-rule="evenodd"
      />
      <path
        d="M8,13.31h21.1c0-.11,0-.21,0-.31s0-.2,0-.31H8c0,.11,0,.21,0,.31s0,.2,0,.31"
        fill-rule="evenodd"
      />
      <path
        d="M.31,13.61V12.39H8l0-.31H0v1.84H8l0-.31Z"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        d="M36.39,12.08H29l0,.31h7.65v1.22H29l0,.31h8V12.08Z"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path d="M29,12.39l0-.31H8l0,.31Z" fill="#fff" fill-rule="evenodd" />
      <path d="M8,13.61l0,.31H29l0-.31Z" fill="#fff" fill-rule="evenodd" />
      <path
        d="M36.39,12.69v.62H29.05c0,.1,0,.2,0,.3h7.65V12.39H29c0,.1,0,.2,0,.3Z"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        d="M.61,13.31v-.62H8c0-.1,0-.2,0-.3H.31v1.22H8c0-.1,0-.2,0-.3Z"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        d="M8,12.69h21.1c0-.1,0-.2,0-.3H8c0,.1,0,.2,0,.3"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        d="M8,13.31c0,.1,0,.2,0,.3H29c0-.1,0-.2,0-.3Z"
        fill="#fff"
        fill-rule="evenodd"
      />
    </g>
  </svg>
</template>

<script>
export default {
    name: "OpenIcon"
};
</script>
