<template>
  <div class="panel__top">
    <h1 class="panel-title" v-if="block.title" v-html="block.title"></h1>
    <div
      class="panel-description"
      v-if="block.description"
      v-html="block.description"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Block",
  props: {
    block: Object,
  },
};
</script>
