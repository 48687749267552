<template>
  <div class="panel__bottom">
    <div class="price" v-if="price && currentStep !== totalSteps">
      <span class="price__label">{{ staticContent.priceLabel }}</span>
      <span class="price__amount">&euro; {{ price }}</span>
    </div>
    <div class="btn-container">
      <button
        @click="previousStep"
        :disabled="isLoading || currentStep === 0"
        class="btn btn--2 btn--prev"
      >
        <PreviousStepIcon />
        {{ staticContent.previous }}
      </button>
      <button
        @click="nextStep"
        v-if="currentStep !== totalSteps"
        :disabled="isLoading || !productData.blockElements[currentStep].isValid"
        class="btn btn--1 btn--next"
      >
        {{ staticContent.next }}
        <NextStepIcon />
      </button>
      <button
        @click="reset"
        v-if="currentStep === totalSteps"
        :disabled="isLoading"
        class="btn btn--4"
      >
        {{ staticContent.reset }}
      </button>
    </div>
    <EmailForm v-if="currentStep === totalSteps" @submitMailForm="submitMailForm"/>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex";

// Components
import EmailForm from "./EmailForm.vue";

// Icons
import NextStepIcon from "../../icons/NextStepIcon.vue";
import PreviousStepIcon from "../../icons/PreviousStepIcon.vue";

export default {
  name: "PanelFooter",
  components: {
    EmailForm,
    NextStepIcon,
    PreviousStepIcon,
  },
  props: {
    productData: Object,
    price: String,
    isLoading: Boolean,
    currentStep: Number,
    totalSteps: Number,
    stringifiedData: String
  },
  computed: {
    ...mapGetters(["staticContent"]),
  },
  methods: {
    previousStep() {
      this.$emit("previousStep");
    },
    nextStep() {
      this.$emit("nextStep");
    },
    reset() {
      this.$emit("reset");
    },
    submitMailForm(emailAddress) {
      this.$emit("submitMailForm", emailAddress)
    }
  },
};
</script>