const state = () => ({
  oAuthToken: '',
  sessionExpired: false,
});

const getters = {
  sessionExpired: (state) => state.sessionExpired,
  oAuthToken: state => state.oAuthToken
};

const mutations = {
  setSessionExpired(state, status) {
    state.sessionExpired = status;
  },
  setAuthToken(state, token) {
    state.oAuthToken = token
  },
  clearAuthToken(state) {
    state.oAuthToken = ''
  }
};

export default {
  state,
  getters,
  mutations,
};
