<template>
    <div>
        <header class="header">
            <div class="header__logo">
                <img src="../../assets/logos/logo-sunway.svg" width="260" height="33" alt="Logo Sunway" />
            </div>
            <transition name="fade" duration="400">
                <div class="header__progress-bar" v-if="progressBarSteps > 0 && productData">
                    <div class="progress-bar">
                        <ul class="progress-bar__steps" :class="[step === progressBarSteps ? 'is-complete' : '']">
                            <li
                                v-for="index in progressBarSteps"
                                :key="index"
                                :class="[index <= step ? 'is-done' : '', index === step + 1 ? 'is-active' : '']"
                            >
                                <transition name="pop-in" duration="400">
                                    <button
                                        @click="goToStep(index)"
                                        v-if="
                                            !productData.blockElements[index].isValid &&
                                                productData.blockElements[index].visited
                                        "
                                    >
                                        !
                                    </button>
                                </transition>
                            </li>
                        </ul>
                        <div class="progress" :style="{ transform: 'scaleX(' + width + ')' }"></div>
                        <div :class="['progress-bar__finish', step === progressBarSteps ? 'is-complete' : '']">
                            <CheckmarkIcon />
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="fade" duration="400">
                <div class="header__product-selector" v-if="productData">
                    <button class="product-selector" @click="toggleProductSelector">
                        {{ staticContent.productSwitch }}
                        <ChevronDownIcon />
                    </button>
                </div>
            </transition>
        </header>
        <div :class="['products', productSelectorOpen ? 'is-open' : 'is-closed']">
            <div class="swiper-container">
                <ul class="swiper-wrapper">
                    <li class="product swiper-slide" v-for="product in productsToNavigateTo" :key="product.id">
                        <button :data-route="'/product/' + product.slug" @click="updateSlug($event, product)">
                            <div class="img-container">
                                <img :src="product.productImg" :width="product.productImgWidth" :height="product.productImgHeight" :alt="product.productTitle" />
                            </div>
                            <h3 v-html="product.productTitle"></h3>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
// Vue / Vuex
import router from '../../router';
import {mapGetters} from 'vuex'

// Libs
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

// Icons
import CheckmarkIcon from '../../icons/CheckmarkIcon.vue'
import ChevronDownIcon from '../../icons/ChevronDownIcon.vue'


export default {
    name: "AppHeader",
    props: ['step', 'steps', 'productData', 'dealerID', 'backUrl', 'backLabel'],
    components: {
        CheckmarkIcon,
        ChevronDownIcon
    },
    data() {
        return {
            swiper: null,
            swiperInitialized: false,
        };
    },
    computed: {
        ...mapGetters(["staticContent", "initialProductData", "productSelectorOpen"]),
        progressBarSteps: function() {
            return this.steps;
        },
        width: function() {
            return (1 / this.progressBarSteps) * this.step;
        },
        productsToNavigateTo() {
            if (this.initialProductData) {
                return this.initialProductData.filter(
                    (product) => !product.parent && this.$route.params.slug !== product.slug
                );
            }
        },
    },
    methods: {
        goToStep(index) {
            this.$emit('updateStepHandler', index);
        },
        toggleProductSelector(event) {
            if (event.target.closest('button')) {
                event.target.closest('button').blur();
            }

            if (!this.swiperInitialized) {
                this.swiper.update();
                this.swiperInitialized = true;
            }

            this.$store.commit("toggleProductSelector")
        },
        serialize(obj) {
            let str = [];
            for (let p in obj)
                if (obj.hasOwnProperty(p)) {
                    str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
                }
            return str.join('&');
        },
        updateSlug(event, product) {
            if (!product.sub) {
                let newRoute = event.target.closest('button').dataset.route;

                // Add query parameters if available
                // Possible parameters: dealer_id, back_url, back_label
                if (this.serialize(this.$route.query).length > 0) {
                    newRoute += '?' + this.serialize(this.$route.query);
                }

                event.target.closest('button').blur();
                this.$store.commit("toggleProductSelector")

                setTimeout(() => {
                    router.push({path: newRoute});
                    this.$emit('updateSlugTriggered', this.$route.params.slug);
                }, 600);
            } else {
                this.$store.commit("showSubProducts", product.ID)
            }
        },
    },
    mounted() {
        this.swiper = new Swiper('.swiper-container', {
            slidesPerView: 'auto',
            freeMode: true,
        });
    },
};
</script>
<style lang="scss" scoped>
@import './src/styles/generic/settings';
@import './src/styles/components/header';
@import './src/styles/components/progress-bar';
</style>
