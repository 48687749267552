<template>
  <div>
    <div class="block__top">
      <h1 class="block-title" v-if="block.title">
        {{ block.title }}
      </h1>
      <div
        class="block-description"
        v-if="block.description"
        v-html="block.description"
      ></div>
    </div>
    <div class="block__main">
      <ul :class="['option-list', block.listClass]">
        <li
          v-for="option in block.customOptions"
          :key="option.color"
          class="list-item"
          :class="option.selected ? 'selected' : ''"
        >
          <input
            type="radio"
            :checked="option.selected"
            :class="option.selected ? 'selected' : ''"
            :id="option.color"
            :value="option.color"
            :name="block.name"
            :disabled="isLoading"
            @change="onRoomColorChange(option.color)"
          />
          <label :for="option.color"></label>
          <div
            class="list-item__image"
            :style="{ backgroundColor: option.color }"
          ></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoomColorDesignspace",
  props: {
    block: Object,
    isLoading: Boolean,
  },
  methods: {
    onRoomColorChange(color) {
      this.$emit("onRoomColorChange", color);
    },
  },
};
</script>