<template>
  <svg
    id="9e9ad9c4-d872-4d6e-9876-5a67b2b5e7d3"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="11"
    viewBox="0 0 20 11"
  >
    <path
      d="M19,1l-9,9L1,1"
      fill="none"
      stroke="#555"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: "ChevronDownIcon",
};
</script>
