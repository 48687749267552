<template>
  <svg
    id="b025f25b-0cac-4704-a459-a2bea3a1cac1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="24"
    viewBox="0 0 32 24"
  >
    <g id="b072d0b9-8add-41c7-913c-9ae87f64ccd4" data-name="envelope">
      <g id="a9c40cdd-401b-414f-9202-73ea1e23f91d" data-name="Stroke-1">
        <path d="M32,24H0V0H32ZM2,22H30V2H2Z" />
      </g>
      <g id="aea0f145-51e5-4969-a08f-145fd4eb2067" data-name="Stroke-3">
        <rect
          x="24.5"
          y="7.57"
          width="2"
          height="14.87"
          transform="translate(-2.75 23.78) rotate(-47.72)"
        />
      </g>
      <rect
        x="-0.93"
        y="14"
        width="14.87"
        height="2"
        transform="translate(-8.4 8.27) rotate(-42.27)"
      />
      <g id="ade64c25-0050-43cc-b82f-e11642b7e8eb" data-name="Stroke-4">
        <path
          d="M16,13a5.38,5.38,0,0,1-3.1-1L.41,2.81,1.59,1.19l12.49,9.16a3.16,3.16,0,0,0,3.84,0L30.41,1.19l1.18,1.62L19.1,12A5.36,5.36,0,0,1,16,13Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "EmailIcon",
};
</script>
