<template>
  <div>
    <AppHeader
      :step="currentStep"
      :steps="totalSteps"
      :productData="productData"
      :dealerID="dealerID"
      @updateStepHandler="updateStep"
      @updateSlugTriggered="resetProduct"
      ref="headerComponent"
    />
    <ConfiguratorPanel
      :productData="productData"
      :currentStep="currentStep"
      :lastStep="lastStep"
      :totalSteps="totalSteps"
      :content="content"
      :price="price"
      @submitMailForm="submitMailForm"
      @onInputChange="onInputChange"
      @onRoomChange="onRoomChange"
      @onRoomColorChange="onRoomColorChange"
      @prevStep="prevStep"
      @nextStep="nextStep"
      @reset="reset"
    />
    <PreviousPage />

    <Modals @init="init" />

    <SelectProduct @subProductClicked="subProductClicked" />
    <ProductRender :productData="productData" />
    <transition name="fade" duration="800">
      <ShadesOpenState
        @openStateClicked="changeOpenState"
        v-show="productData && !mainImgLoading && productOpenState"
      />
    </transition>
  </div>
</template>

<script>
// Libs / Scripts
import axios from "axios";
import api from "../api";

// Vue / Vuex
import router from "../router";
import { mapGetters } from "vuex";

// Components
import SelectProduct from "./SelectProduct.vue";
import AppHeader from "../components/layout/AppHeader.vue";
import ConfiguratorPanel from "../components/layout/ConfiguratorPanel.vue";
import ProductRender from "../components/layout/ProductRender.vue";
import Modals from "../components/configurator/ConfiguratorModals.vue";
import PreviousPage from "../components/ui/PreviousPage.vue";
import ShadesOpenState from "../components/configurator/ShadesOpenState.vue";
import Loader from "../components/ui/Loader.vue";

export default {
  name: "Home",
  components: {
    SelectProduct,
    AppHeader,
    ConfiguratorPanel,
    ProductRender,
    Modals,
    PreviousPage,
    ShadesOpenState,
    Loader,
  },
  data() {
    return {
      loadingInterval: null,
      staticSteps: false,
      productData: null,
      dealerID: this.$route.query.dealer_id,
      dealerData: null,
      currentStep: 0,
      lastStep: 0,
      content: {
        savedBlocks: null,
        blocks: [],
      },
      roomUrl: null,
      roomSelector: null,
      roomOptions: null,
      roomColorOptions: null,
      productColour: null,
      productColorPropertyProduct: null,
      productColorPropertyFabricWidth: null,
      productColorPropertyTransparancy: null,
      roomColorProperty: null,
      productOpenState: null,
    };
  },
  computed: {
    ...mapGetters([
      "sessionExpired",
      "oAuthToken",
      "staticContent",
      "initialProductData",
      "topLevelProducts",
      "subLevelProducts",
      "mainImgLoading",
      "product",
      "selectedRoomIndex",
      "selectedRoomColor",
      "roomSet",
      "mainImgBackgroundImage",
      "mainImgBackgroundColorFirst",
      "mainImgBackgroundColorSecond",
      "mainBackground",
      "productImage",
    ]),
    productColourProperties() {
      let str = "D_25_LF";
      if (
        this.productColorPropertyProduct &&
        this.productColorPropertyFabricWidth &&
        this.productColorPropertyTransparancy
      ) {
        str =
          this.productColorPropertyProduct +
          "_" +
          this.productColorPropertyFabricWidth +
          "_" +
          this.productColorPropertyTransparancy;
      }
      return str;
    },
    totalSteps: function () {
      if (this.productData) {
        return this.productData.blockElements.length - 1;
      } else {
        return 0;
      }
    },
    price: function () {
      if (this.productData && this.currentStep > 0) {
        let price = this.productData.price * 1.21;

        if (this.dealerData) {
          if ("none" === this.dealerData.priceSettings) {
            return null;
          }

          if (
            "amount" === this.dealerData.priceSettings &&
            this.dealerData.addPriceAmount &&
            price > 0
          ) {
            price += parseFloat(this.dealerData.addPriceAmount);
          }

          if (
            "percentage" === this.dealerData.priceSettings &&
            this.dealerData.addPricePercentage &&
            price > 0
          ) {
            price =
              price +
              (price / 100) * parseFloat(this.dealerData.addPricePercentage);
          }
        }

        return price.toFixed(2);
      } else {
        return null;
      }
    },
    json: function () {
      const summaryBlock = this.productData.blockElements.find(
        (blockElement) => blockElement.name === "summary"
      );
      return JSON.stringify(
        Object.assign(summaryBlock, {
          room_image: this.product.selectedImage,
          prod_color: this.mainImgBackgroundColorFirst,
          prod_image: this.mainImgBackgroundImage,
          show_prod_image: this.product.showBackgroundImage,
          price: this.price,
          dealer_id: this.dealerID,
        })
      );
    },
    roomBackgroundTexture: function () {
      let texture;
      if (this.roomColorOptions && this.product) {
        let selectedRoomColor = this.roomColorOptions.find(
          (color) => color.selected
        ).color;

        if (this.roomColorOptions && this.product && selectedRoomColor) {
          for (const prop in this.product.rooms.colors
            .designspaceWallTextures) {
            if (
              this.product.rooms.colors.designspaceWallTextures[prop].color ===
              selectedRoomColor
            ) {
              texture =
                this.product.rooms.colors.designspaceWallTextures[prop]
                  .wall_texture;
            }
          }
        }
      }

      return texture;
    },
  },
  watch: {
    $route: function () {
      this.$store.commit("setSlug", this.$route.params.slug);
      this.selectProduct();
    },
    currentStep: function (currentStep, lastStep) {
      this.lastStep = lastStep;
    },
  },
  methods: {
    init() {
      api
        .getOAthToken()
        .then((res) => {
          // Session Store
          this.$store.commit("setAuthToken", res.data.access_token);
          this.$store.commit("setSessionExpired", false);

          if (!this.productData) {
            this.selectProduct();
          }
        })
        .catch((error) => {
          console.log("no token found, auth error: ", { error });
        });
    },
    resetProduct() {
      this.currentStep = 0;
      this.lastStep = 0;
      this.$store.commit("resetMainBackgroundColor", 1);
      this.$store.commit("resetMainBackgroundColor", 2);
      this.$store.commit("setMainBackgroundImage", null);
      this.$store.commit("setSelectedRoomIndex", 0);
      this.$store.commit("setSelectedRoomColor", null);
      this.$store.commit("setLoading", true);
      this.$store.commit("setLoadingFirstTime", true);
      this.productData = null;
      this.roomOptions = null;
      this.roomColorOptions = null;
      (this.roomUrl = null), this.$store.commit("setIsUpdatingStep", false);
      this.$store.commit("clearStepLoadingText");
      this.content = {
        savedBlocks: null,
        blocks: [],
      };
      this.$store.commit("setMainImgLoading", true);
      this.staticSteps = false;
      this.$store.commit("setMailResponse", null);
      this.$store.commit("setMailResponseContent", null);
      this.roomSelector = null;
      this.productColour = null;
      this.productColorPropertyProduct = null;
      this.productColorPropertyFabricWidth = null;
      this.productColorPropertyTransparancy = null;
      this.roomColorProperty = null;
      this.productOpenState = null;
      this.$store.commit("hideSubProducts");
    },
    reset() {
      router.push({ path: "/" });
      this.resetProduct();
    },
    resetScrollPosition() {
      const panel = document.querySelector(".panel__main");
      panel.scrollTop = 0;
    },
    prevStep() {
      this.$store.commit("setIsUpdatingStep", true);
      this.$store.commit(
        "setStepLoadingText",
        this.staticContent.loadingPrev
      );
      this.currentStep--;
      this.updateStep();
    },
    nextStep() {
      this.$store.commit("setIsUpdatingStep", true);
      this.$store.commit(
        "setStepLoadingText",
        this.staticContent.loadingNext
      );
      this.currentStep++;
      this.updateStep();
    },
    updateStep(newStepIndex) {
      if (this.currentStep === newStepIndex) return false;
      if (newStepIndex) this.currentStep = newStepIndex;
      this.productData.blockElements[this.currentStep].visited = true;
      this.updateProductData();

      setTimeout(() => {
        this.resetScrollPosition();
      }, 500);
    },
    selectProduct() {
      if (this.product) {
        if (!this.product.roomURL) {
          // This is the non Design Space block

          const productImage = this.product.rooms.rooms[0].colors[0].mainImage;
          this.$store.commit("setProductImage", productImage);
          const newColor = this.product.rooms.rooms[0].colors[0].color;
          this.$store.commit("setSelectedRoomColor", newColor);
        } else {
          // This is the Design Space block
          this.productColour =
            this.product.roomURLParameters.productColour.value;
          this.productColorPropertyProduct =
            this.product.roomURLParameters.productColourProperties.find(
              (prop) => prop.id === "product"
            ).value;
          this.productColorPropertyFabricWidth =
            this.product.roomURLParameters.productColourProperties.find(
              (prop) => prop.id === "FabricWidth"
            ).value;
          this.productColorPropertyTransparancy =
            this.product.roomURLParameters.productColourProperties.find(
              (prop) => prop.id === "FabricTranparency"
            ).value;

          this.productOpenState = {
            name: "half",
            value: this.product.rooms.rooms[0].openStates["half"],
          };

          const mainImageDSUrl = this.replaceUrlVariables(
            this.product.roomURL,
            {
              roomSet: this.roomSet,
              productColour: this.productColour,
              productColourProperties: this.productColourProperties,
              wallTexture: this.roomBackgroundTexture,
              openState: this.productOpenState.value,
            }
          );

          this.$store.dispatch("changeMainImage", mainImageDSUrl);
        }

        if (this.product.loadingTxts) {
          this.$store.commit(
            "setStaticContentLoadingText",
            this.product.loadingTxts
          );
          this.$store.dispatch("rotateLoadingText", this.product.loadingTxts);
        }

        api
          .selectProduct(this.product.productID, this.oAuthToken)
          .then((res) => {
            this.content.savedBlocks = [];
            this.updateProductData(res.data);
          })
          .catch((error) => {
            if (error.status === 401) {
              console.log("Uw sessie is verlopen!");
              this.$store.commit("setSessionExpired", true);

              if (this.product.loadingTxts) {
                this.$store.commit(
                  "setStaticContentLoadingText",
                  this.product.loadingTxts
                );
              }

              clearInterval(this.loadingInterval);
            }
            this.$store.commit("clearStepLoadingText");
          })
          .finally(() => {
            this.preloadAllRoomImages();
          });
      }
    },
    preloadAllRoomImages() {
      if (!this.product.roomURL && this.roomColorOptions) {
        // If not a Designspace product, preload images that are not yet rendered so they load quicker when selected
        const currentRoomColor = this.roomColorOptions
          .find((option) => option.selected)
          .color.replace("#", "");

        const otherRoomColors = this.roomColorOptions.filter(
          (option) => !option.selected
        );

        setTimeout(() => {
          otherRoomColors.forEach((option) => {
            const newColour = option.color.replace("#", "");
            const image = new Image();
            const src = this.productImage.replace(currentRoomColor, newColour);
            image.src = src;
          });
        }, 1000);
      }
    },
    subProductClicked() {
      this.resetProduct();
      this.$store.commit("setProductSelector", false);
    },
    toDataURL(url, callback) {
      var httpRequest = new XMLHttpRequest();
      httpRequest.onload = function () {
        var fileReader = new FileReader();
        fileReader.onloadend = function () {
          callback(fileReader.result);
        };
        fileReader.readAsDataURL(httpRequest.response);
      };
      httpRequest.open("GET", url);
      httpRequest.responseType = "blob";
      httpRequest.send();
    },
    updateProductData(productData, triggerIsListItemChange) {
      this.$store.commit("setLoading", true);

      if (!productData) {
        productData = this.productData;
      } else {
        this.staticSteps = false;
      }

      if (!this.staticSteps) {
        addStaticSteps.call(this);
      }

      function addStaticSteps() {
        // Add static block element

        if (!this.roomSelector) {
          const roomSelector = {
            title: this.product.rooms.title,
            description: this.product.rooms.description,
            itemType: "Block",
            name: "room-selector",
            isValid: true,
            rooms: this.initialProductData[0].rooms,
            colors: this.initialProductData[0].rooms.colors,
            blockElements: [
              {
                itemType: "RoomSelector",
                name: "room",
                listClass: "option-list--cards",
                customOptions: this.product.rooms.rooms.map((room, index) => {
                  return {
                    thumb: room.thumbnail,
                    image: room.colors[0].mainImage,
                    selected: index === 0 ? true : false,
                    key: room.key,
                    value: index,
                    data: {
                      title: room.title,
                    },
                    openStates: room.openStates,
                  };
                }),
              },
              {
                title: this.product.rooms.colors.title,
                description: this.product.rooms.colors.description,
                itemType: "RoomColorSelector",
                name: "room-color",
                listClass: "option-list--smaller",
                customOptions: this.product.rooms.colors.colors.map(
                  (color, index) => {
                    return {
                      color: color,
                      selected: index === 0 ? true : false,
                    };
                  }
                ),
              },
            ],
          };

          const roomSelectorOptions = roomSelector.blockElements.find(
            (element) => element.itemType === "RoomSelector"
          );
          this.roomOptions = roomSelectorOptions.customOptions;

          const roomColorOptions = roomSelector.blockElements.find(
            (element) => element.itemType === "RoomColorSelector"
          );
          this.roomColorOptions = roomColorOptions.customOptions;

          productData.blockElements.unshift(roomSelector);
          this.roomSelector = roomSelector;
        } else {
          productData.blockElements.unshift(this.roomSelector);
        }

        // Add overview block element
        const overview = {
          title: this.staticContent.summaryTitle,
          description: "<p>" + this.staticContent.summaryContent + "</p>",
          name: "summary",
          itemType: "Block",
          isValid: true,
        };

        productData.blockElements.push(overview);

        this.staticSteps = true;
      }

      // Add content to contentItems
      productData = addContentProviders.call(this, productData);

      function addContentProviders(blockData, stepIndex) {
        if (blockData.blockElements) {
          blockData.blockElements = blockData.blockElements.map(
            (blockElement, index) => {
              // Add stepIndex to blockElements
              if (!stepIndex) stepIndex = 0;
              if (blockElement.itemType === "Block") {
                stepIndex = index;
                blockElement.isValid = true;
              }
              blockElement.stepIndex = stepIndex;

              // Set id to name if there is no id
              if (!blockElement.id) blockElement.id = blockElement.name;

              // Add error messages to blockElement
              if (productData.messages) {
                blockElement.messages = productData.messages.filter(
                  (message) => {
                    if (message.severity === 0) {
                      message.className = "info";
                    } else if (message.severity === 1) {
                      message.className = "warning";
                    } else if (message.severity === 2) {
                      message.className = "error";
                    }
                    return message.blockElementId === blockElement.id;
                  }
                );
              } else {
                blockElement.messages = [];
              }

              // Set step to invalid if errorMessage has severity 2
              const blockElementIsValid = !blockElement.messages.some(
                (message) => {
                  return message.severity === 2;
                }
              );

              if (!blockElementIsValid) {
                productData.blockElements[
                  blockElement.stepIndex
                ].isValid = false;
              }

              // Add contentProvider to option
              if (blockElement.options) {
                blockElement.options.forEach((option) => {
                  if (option.contentItems) {
                    option.contentItems.forEach(addContentProvider);
                  }
                });
              }

              // Add contentProvider to contentItem
              if (blockElement.contentItems) {
                blockElement.contentItems.forEach(addContentProvider);

                // Sort contentItems to new index
                blockElement.contentItems = blockElement.contentItems.sort(
                  (a, b) => {
                    return a.contentProvider.index - b.contentProvider.index;
                  }
                );
              }

              // Merge saved block to blockElement
              const savedBlock = this.content.savedBlocks.find(
                (block) => block.id === blockElement.id
              );
              if (savedBlock)
                blockElement = Object.assign(blockElement, savedBlock);

              // Function to add contentProvider to contentItem
              function addContentProvider(contentItem) {
                var tempContentProvider = productData.contentProviders.find(
                  (contentProvider, index) => {
                    contentProvider.index = index;
                    return contentProvider.id === contentItem.contentProviderId;
                  }
                );

                contentItem.contentProvider = {
                  index: tempContentProvider ? tempContentProvider.index : null,
                  url: tempContentProvider ? tempContentProvider.url : null,
                  contentType: tempContentProvider
                    ? tempContentProvider.contentType
                    : null,
                  contentProviderType: tempContentProvider
                    ? tempContentProvider.contentProviderType
                    : null,
                };

                contentItem.type = contentItem.contentProvider.contentType;

                if (
                  contentItem.parameterValues &&
                  contentItem.parameterValues.length
                ) {
                  contentItem.parameterValues.forEach(function (
                    parameterValue
                  ) {
                    contentItem.contentProvider.url =
                      contentItem.contentProvider.url.replace(
                        "{" + parameterValue.name + "}",
                        parameterValue.value
                      );
                  });
                }
              }

              // Repeat convertBlockData if blockElement has blockElements
              if (blockElement.blockElements)
                blockElement = addContentProviders.call(
                  this,
                  blockElement,
                  stepIndex
                );

              return blockElement;
            }
          );
        }
        return blockData;
      }
      this.fetchData(productData, triggerIsListItemChange);
    },
    fetchData(productData, triggerIsListItemChange) {
      let promises = [];

      fetchBlockData.call(this, productData.blockElements[this.currentStep]);

      Promise.all(promises)
        .then(() => {
          this.updateData(productData);

          if (!triggerIsListItemChange) {
            const colorBlock = productData.blockElements[
              this.currentStep
            ].blockElements.find(function (blockElement) {
              return (
                blockElement.name === "ProductColour" ||
                blockElement.name === "FabricColour1"
              );
            });

            const secondColorBlock = productData.blockElements[
              this.currentStep
            ].blockElements.find(function (blockElement) {
              return blockElement.name === "FabricColour2";
            });

            if (colorBlock && !secondColorBlock) {
              // only one fabric color available
              const selectedFirstColor = colorBlock.options.find(function (
                color
              ) {
                return color.selected;
              });

              if (selectedFirstColor) {
                this.$store.commit("setMainBackgroundColor", {
                  color: selectedFirstColor.data.average_color,
                  number: 1,
                });
                this.$store.commit("resetMainBackgroundColor", 2);
                this.$store.commit(
                  "setMainBackgroundImage",
                  selectedFirstColor.data.image
                );
              }
            }

            if (colorBlock && secondColorBlock && secondColorBlock.isEnabled) {
              // second fabric color available
              const selectedFirstColor = colorBlock.options.find(function (
                color
              ) {
                return color.selected;
              });

              if (selectedFirstColor) {
                this.$store.commit("setMainBackgroundColor", {
                  color: selectedFirstColor.data.avarage_color,
                  number: 1,
                });
                this.$store.commit("resetMainBackgroundColor", 2);
                this.$store.commit(
                  "setMainBackgroundImage",
                  selectedFirstColor.data.image
                );
              }

              const selectedSecondColor = secondColorBlock.options.find(
                function (color) {
                  return color.selected;
                }
              );

              if (selectedSecondColor) {
                this.$store.commit("setMainBackgroundColor", {
                  color: selectedSecondColor.data.average_color,
                  number: 2,
                });
              }
            }
          }
        })
        .catch((error) => {
          console.log("Not all promises resolved", error);

          this.$store.commit("setLoading", false);
          this.$store.commit("setLoadingFirstTime", false);
        });

      // Add content to contentItems
      function fetchBlockData(blockElement) {
        if (blockElement.contentItems) {
          blockElement.contentItems.forEach((contentItem, index) => {
            promises.push(
              new Promise((resolve, reject) => {
                api
                  .getContentProviderData(contentItem.contentProvider.url)
                  .then((res) => {
                    if (res.data) {
                      contentItem.data = res.data;
                    } else {
                      contentItem.data = blockElement.label;
                    }
                    return contentItem;
                  })
                  .catch((err) => {
                    console.log("Could not get content provider data: ", err);
                    contentItem.data = blockElement.label;
                    return contentItem;
                  })
                  .finally(() => {
                    resolve(true);
                  });
              })
            );
          });
        }

        if (blockElement.itemType === "ListProperty") {
          if (blockElement.options.length > 0) {
            if (blockElement.options[0].contentItems) {
              let url =
                blockElement.options[0].contentItems[0].contentProvider.url;

              url = url.replace(
                /\/(Property|Option)\/([^\/]+)\/.*$/,
                function ($1, $2, $3) {
                  return "/Option/" + $3 + "/GetAll";
                }
              );

              promises.push(
                new Promise((resolve, reject) => {
                  api
                    .getContentProviderData(url)
                    .then((res) => {
                      const data = res.data;
                      if (data) {
                        blockElement.optionsData = Object.values(data);
                        blockElement.options.forEach((option) => {
                          option.data = blockElement.optionsData.find(
                            (optionData) => {
                              var parameter =
                                option.contentItems[0].parameterValues.find(
                                  (parameterValue) =>
                                    parameterValue.name === "Value"
                                );

                              if (typeof parameter !== "undefined") {
                                return (
                                  parameter &&
                                  parameter.value === optionData.value
                                );
                              } else {
                                return (
                                  option.contentItems[0].parameterValues[0]
                                    .value === optionData.value
                                );
                              }
                            }
                          );

                          if (!option.data) {
                            // option.data = {}
                            option.data = {
                              value: option.displayValue,
                              title: option.displayValue,
                            };
                          }
                        });
                      } else {
                        blockElement.options.forEach((option) => {
                          // if there are no content providers fall back on displayValue from HD api
                          option.data = {
                            value: option.displayValue,
                            title: option.displayValue,
                          };
                        });
                      }

                      if (
                        (blockElement.name === "FabricColour1" ||
                          blockElement.name === "FabricColour2") &&
                        this.product.productThumbnailURL
                      ) {
                        blockElement.options.forEach((option) => {
                          const transparency = option.data.transparency || "LF";
                          const productColourProperties =
                            this.productColorPropertyProduct +
                            "_" +
                            this.productColorPropertyFabricWidth +
                            "_" +
                            transparency;

                          option.data.thumbnail = this.replaceUrlVariables(
                            this.product.productThumbnailURL,
                            {
                              productColourProperties: productColourProperties,
                              productColour: option.data.title,
                            }
                          );

                          option.data.image = this.replaceUrlVariables(
                            this.product.productDetailURL,
                            {
                              productColourProperties: productColourProperties,
                              productColour: option.data.title,
                            }
                          );
                        });
                      }

                      resolve(true);
                    })
                    .catch((error) => {
                      console.log("Could not get content provider data: ", {
                        error,
                      });
                      blockElement.options.forEach((option) => {
                        // if there are no content providers fall back on displayValue from HD api
                        option.data = {
                          value: option.displayValue,
                          title: option.displayValue,
                        };
                      });
                      // reject('GetAll call error');
                    })
                    .finally(() => {
                      resolve(true);
                    });
                })
              );
            } else {
              blockElement.options.forEach((option) => {
                option.data = {
                  value: option.displayValue,
                  title: option.displayValue,
                };
              });
            }
          }
        }

        if (blockElement.blockElements) {
          blockElement.blockElements.forEach(fetchBlockData.bind(this));
        }
      }
      this.$store.commit("clearStepLoadingText");
    },
    updateData(productData) {
      // Empty block array
      this.content.blocks = [];

      // Overview data handling
      const summaryBlock = productData.blockElements.find(
        (blockElement) => blockElement.name === "summary"
      );

      summaryBlock.blockElements = productData.summary.summaryBlockElements.map(
        (summaryBlockElement) => {
          const blockElement = productData.blockElements.find(
            (blockElement) => blockElement.name === summaryBlockElement.name
          );

          return {
            title: blockElement.title,
            description: null,
            name: summaryBlockElement.name,
            itemType: "SummaryBlock",
            summaryItems: summaryBlockElement.summaryBlockElements.map(
              (item) => {
                // Custom data for overview
                item.title = item.name;
                const tempId = item.blockElementId.replace("//", "_");
                const tempVar = blockElement.blockElements.find(
                  (blockElement) => blockElement.id === tempId
                );

                if (tempVar && tempVar.title) {
                  item.title = tempVar.title;
                }

                if (
                  typeof item.value === "string" &&
                  item.value.indexOf("<sup>®</sup>") === -1
                )
                  item.value = item.value.replace(/®/g, "<sup>®</sup>");

                if (item.itemType === "BooleanProperty") {
                  if (item.value === true) {
                    item.value = "Ja";
                  } else if (item.value === false) {
                    item.value = "Nee";
                  }
                }

                return item;
              }
            ),
          };
        }
      );

      summaryBlock.blockElements.push({
        title: null,
        description: null,
        name: "SummaryPriceBlock",
        itemType: "SummaryPriceBlock",
      });

      updateBlockData.call(this, productData.blockElements[this.currentStep]);

      function updateBlockData(blockElement) {
        if (blockElement.contentItems) {
          blockElement.title = blockElement.contentItems.find(
            (contentItem) => contentItem.type === "Text"
          );
          if (blockElement.title) {
            blockElement.title = blockElement.title.data;
            blockElement.title = blockElement.title.replace(
              /®/g,
              "<sup>®</sup>"
            );
          }

          blockElement.description = blockElement.contentItems.find(
            (contentItem) => contentItem.type === "Html"
          );
          if (blockElement.description) {
            blockElement.description = blockElement.description.data;
            blockElement.description = blockElement.description.replace(
              /®/g,
              "<sup>®</sup>"
            );
          }
        }

        if (!blockElement.title) blockElement.title = null;
        if (!blockElement.description) blockElement.description = null;

        if (blockElement.itemType === "ListProperty") {
          blockElement.listClass = "option-list--small";

          blockElement.options.forEach((option) => {
            // Create unique id for list propery items
            option.id =
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15);

            option.selected = option.key === blockElement.value ? true : false;

            if (option.data.title) {
              option.data.title = option.data.title.replace(
                /®/g,
                "<sup>®</sup>"
              );

              if (option.data.title.length > 8) {
                blockElement.listClass = "option-list--large";
              }
            }
          });
        }

        const savedBlock = this.content.savedBlocks.find(
          (block) => block.id === blockElement.id
        );
        if (!savedBlock) {
          this.content.savedBlocks.push({
            id: blockElement.id,
            title: blockElement.title,
            description: blockElement.description,
            visited: blockElement.visited,
          });
        }

        if (blockElement.isEnabled !== false) {
          this.content.blocks.push(blockElement);
        }

        if (blockElement.blockElements) {
          blockElement.blockElements.forEach(updateBlockData.bind(this));
        }
      }

      window.setTimeout(() => {
        this.$store.commit("setIsUpdatingStep", false);
        this.$store.commit("clearStepLoadingText");
      }, 100);

      this.$store.commit("setLoading", false);
      this.$store.commit("setLoadingFirstTime", false);
      this.productData = productData;
    },
    onInputChange(event, block, option) {
      this.$store.commit("setLoading", true);
      this.$store.commit("setStepLoadingText", this.staticContent.loadingProduct);

      const value = event.target.value;
      const id = option ? block.id : event.target.id;
      option ? (block.value = option.key) : (block.value = value);

      if (option) {
        block.options.forEach((optionItem) => {
          optionItem.selected = false;
        });
        option.selected = true;

        if (block.name === "ProductColour") {
          // this.mainImgBackgroundImage = option.data.image;
          this.$store.commit("setMainBackgroundImage", option.data.image);
        }

        // When the input changed is the 'Plooibreedte' option and this product is a Design Space product, then update main image with a new Design Space image
        if (event.target.name === "FabricWidth" && this.product.roomURL) {
          const fabricWidthValue = parseInt(option.displayValue);
          this.productColorPropertyFabricWidth = fabricWidthValue;

          const mainImageDSUrl = this.replaceUrlVariables(
            this.product.roomURL,
            {
              roomSet: this.roomSet,
              productColour: this.productColour,
              productColourProperties: this.productColourProperties,
              wallTexture: this.roomBackgroundTexture,
              openState: this.productOpenState.value,
            }
          );

          this.$store.dispatch("changeMainImage", mainImageDSUrl);
        }

        if (
          (event.target.name === "FabricColour1" ||
            event.target.name === "FabricColour2") &&
          this.product.roomURL
        ) {
          this.productColour = option.data.title;
          this.productColorPropertyTransparancy = option.data.transparency;

          const mainImageDSUrl = this.replaceUrlVariables(
            this.product.roomURL,
            {
              roomSet: this.roomSet,
              productColour: this.productColour,
              productColourProperties: this.productColourProperties,
              wallTexture: this.roomBackgroundTexture,
              openState: this.productOpenState.value,
            }
          );

          this.$store.dispatch("changeMainImage", mainImageDSUrl);
        }
      }

      if (
        event.target.getAttribute("name") === "FabricColour1" ||
        event.target.getAttribute("name") === "ProductColour"
      ) {
        this.$store.commit("setMainBackgroundColor", {
          color: option.data.average_color,
          number: 1,
        });
      }

      if (event.target.getAttribute("name") === "FabricColour2") {
        this.$store.commit("setMainBackgroundColor", {
          color: option.data.average_color,
          number: 2,
        });
      }

      const url =
        api.settings.serviceUrl +
        api.settings.serviceUrlSET +
        "?id=" +
        id +
        "&value=" +
        value;
      api
        .getProductData(url, "PUT", this.oAuthToken)
        .then((res) => {
          const productData = res.data;
          this.updateProductData(productData, true);
        })
        .catch((err) => {
          console.log("Could not get product data: ", { err });
          this.$store.commit("clearStepLoadingText");
        });
    },
    onRoomChange(event, id) {
      if (!id) id = event.target.id;
      const value = parseInt(event.target.value);

      this.roomOptions.forEach((option) => {
        if (option.key === id) {
          option.selected = true;
          this.$store.commit("setSelectedRoomIndex", value);

          if (this.product.roomURL) {
            // Designspace implementation
            const currentOpenStateName = this.productOpenState.name;
            this.productOpenState = {
              name: currentOpenStateName,
              value: option.openStates[currentOpenStateName],
            };

            const mainImageDSUrl = this.replaceUrlVariables(
              this.product.roomURL,
              {
                roomSet: this.roomSet,
                productColour: this.productColour,
                productColourProperties: this.productColourProperties,
                wallTexture: this.roomBackgroundTexture,
                openState: this.productOpenState.value,
              }
            );

            this.$store.dispatch("changeMainImage", mainImageDSUrl);
          } else {
            this.$store.dispatch("changeMainImage");
            this.preloadAllRoomImages()
          }
        } else {
          option.selected = false;
        }
      });
    },
    onRoomColorChange(color) {
      this.roomColorOptions.forEach((option) => {
        if (option.color === color) {
          this.$store.commit("setSelectedRoomColor", color);

          option.selected = true;

          if (this.product.roomURL) {
            // Designspace implementation
            const mainImageDSUrl = this.replaceUrlVariables(
              this.product.roomURL,
              {
                roomSet: this.roomSet,
                productColour: this.productColour,
                productColourProperties: this.productColourProperties,
                wallTexture: this.roomBackgroundTexture,
                openState: this.productOpenState.value,
              }
            );

            this.$store.dispatch("changeMainImage", mainImageDSUrl);
          } else {
            this.$store.dispatch("changeMainImage");
          }
        } else {
          option.selected = false;
        }
      });
    },
    changeOpenState(openState) {
      this.productOpenState = {
        name: openState,
        value: this.roomOptions.find((room) => room.selected).openStates[
          openState
        ],
      };

      const mainImageDSUrl = this.replaceUrlVariables(this.product.roomURL, {
        roomSet: this.roomSet,
        productColour: this.productColour,
        productColourProperties: this.productColourProperties,
        wallTexture: this.roomBackgroundTexture,
        openState: this.productOpenState.value,
      });

      this.$store.dispatch("changeMainImage", mainImageDSUrl);
    },
    submitMailForm(emailAddress) {
      this.$store.dispatch("submitMailForm", { emailAddress, json: this.json, emailLoadingtext: this.staticContent.loadingEmail });
    },
    replaceUrlVariables(url, options) {
      if (url) {
        const optionKeys = Object.keys(options);
        optionKeys.forEach((key) => {
          const value = options[key];
          url = url.replace("{" + key + "}", value);
        });
      }

      return url;
    },
    created() {
      if (this.dealerID) {
        api.getDealerData(this.dealerID).then((data) => {
          if (Object.keys(data).length > 0) {
            this.dealerData = {
              priceSettings: data.pc_show_price ? data.pc_show_price[0] : null,
              addPriceAmount: data.pc_add_price_amount
                ? parseInt(data.pc_add_price_amount[0])
                : null,
              addPricePercentage: data.pc_add_price_percentage
                ? parseInt(data.pc_add_price_percentage[0])
                : null,
            };
          }
        });
      }

      return url;
    },
  },
  created() {
    if (this.dealerID) {
      api.getDealerData(this.dealerID).then((data) => {
        if (data.length > 0) {
          data = data[0];
          this.dealerData = {
            priceSettings: data.pc_show_price ? data.pc_show_price[0] : null,
            addPriceAmount: data.pc_add_price_amount
              ? parseInt(data.pc_add_price_amount[0])
              : null,
            addPricePercentage: data.pc_add_price_percentage
              ? parseInt(data.pc_add_price_percentage[0])
              : null,
          };
        }
      });
    }

    axios({
      url: process.env.BASE_URL + "environment.json",
      method: "GET",
      crossDomain: true,
    })
      .then((res) => {
        // resolve(response);
        api.getInitialData(res.data.initialDataUrl).then(({ data }) => {
          this.$store.commit("setStaticContent", { ...data.labels });
          this.$store.commit("setMailUrl", data.mailUrl);

          if (data.products.length > 0) {
            data.products.forEach((product) => {
              product.productTitle = product.productTitle.replace(
                /®/g,
                "<sup>®</sup>"
              );
            });
            this.$store.commit("setInitialProductData", data.products);
            this.init();
          }
        });
      })
      .catch((error) => {
        console.log({ error });
        // resolve('');
      });
  },
};
</script>

<style lang="scss">
@import "./src/styles/generic/settings";
@import "./src/styles/components/selector-panel";
@import "./src/styles/components/product-selector";
@import "./src/styles/components/room-selector";
</style>
