<template>
  <div :class="['block', !block.isValid ? 'block--error' : '']">
    <div class="block__top">
      <h2 class="block-title" v-if="block.title">
        {{ block.title }}
        <button
          v-if="block.description"
          @click="openContentModal"
          class="btn-help"
          :data-html="block.description"
        ></button>
      </h2>
    </div>
    <div class="block__main block__main--integer custom-form">
      <input
        type="number"
        :value="block.value"
        :id="block.id"
        :name="block.name"
        :disabled="isLoading"
        @change="onInputChange($event, block)"
        onfocus="this.select();"
      />
    </div>
    <div class="block__messages" v-if="block.messages.length > 0">
      <ul>
        <li
          v-for="message in block.messages"
          :class="message.className"
          :key="message.blockElementId"
        >
          {{ message.text }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "NumberInput",
  props: {
    block: Object,
    isLoading: Boolean,
  },
  methods: {
    onInputChange(event, block) {
      this.$emit("onInputChange", event, block);
    },
    openContentModal(event) {
      this.$store.commit("openContentModal", event);
    },
  },
};
</script>