import api from "../../api";

const state = () => ({
  mailUrl: null,
  mailResponse: false,
  mailResponseContent: null,
});

const getters = {
  mailResponse: state => state.mailResponse,
  mailResponseContent: state => state.mailResponseContent
};

const actions = {
  submitMailForm({ state, commit, rootGetters }, {emailAddress, json, emailLoadingtext}) {
    commit("setLoading", true, {root: true})
    commit("setStepLoadingText", emailLoadingtext, {root: true})

    let data = {
      json: json,
      address: emailAddress,
      product_id: rootGetters.product.ID,
    };

    api
      .sendMail(data, state.mailUrl)
      .then((res) => {
        commit("setMailResponseContent", {
          title: res.data.title,
          message: res.data.content,
          btnText: res.data.btnText,
          success: res.data.success,
        });

        commit("setMailResponse", true);
        commit("setLoading", false, {root: true})
        commit("clearStepLoadingText", {root: true})

      })
      .catch((err) => {
        console.log("form submit failed", {err});
        commit("setLoading", false, {root: true})
        commit("clearStepLoadingText", {root: true})
      });
  },
};

const mutations = {
  setMailUrl(state, url) {
    state.mailUrl = url;
  },
  setMailResponse(state, value) {
    state.mailResponse = value;
  },
  setMailResponseContent(state, data) {
    state.mailResponseContent = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
