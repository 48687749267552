<template>
  <transition name="modal-animation" duration="400">
    <div class="modal" v-if="showModal" :class="customClass">
      <div class="modal__inner">
        <div class="modal__header">
          <slot name="modal-header"></slot>
          <button class="close-btn" @click="closeModalClicked" v-if="closeBtn">
            <CloseModalIcon />
          </button>
        </div>
        <div class="modal__body">
          <slot name="modal-body"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// Icons
import CloseModalIcon from "../../icons/CloseModalIcon.vue";
export default {
  name: "Modal",
  components: {
    CloseModalIcon,
  },
  props: ["showModal", "closeBtn", "customClass"],
  methods: {
    closeModalClicked() {
      this.$emit("closeModalClickedHandler");
    },
  },
};
</script>
<style lang="scss">
@import "./src/styles/generic/settings";
@import "./src/styles/components/modal";
</style>
