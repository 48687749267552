<template>
  <button class="toggle-btn" @click="toggleSelectorPanel">
    <TogglePanelIcon />
  </button>
</template>

<script>
// Icons
import TogglePanelIcon from "../../icons/TogglePanelIcon.vue";

export default {
  name: "ExpandPanelButton",
  components: {
    TogglePanelIcon,
  },
  methods: {
    toggleSelectorPanel() {
      this.$emit("toggleSelectorPanel");
    },
  },
};
</script>