<template>
  <svg
    class="icon"
    width="11"
    height="20"
    viewBox="0 0 11 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1l9 9-8.999 9"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "PreviousStepIcon",
};
</script>