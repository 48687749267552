import axios from "axios";
import qs from "qs";

const settings = {
  oAuthUrl: "https://identity.sunway.nl/core/connect/token",
  oAuth: {
    grant_type: "password",
    username: "foursites",
    password: "foursites",
    scope: "configuratorapi",
    client_id: "directconnect",
    client_secret: "secret",
  },
  serviceUrl: "https://configurator.sunway.nl/api/v1.0/Configurator/",
  serviceUrlGET: "start/{PID}",
  serviceUrlSET: "setvalue",
  contentProviders: {
    auth: {
      api_key: "YouShallNotPass",
    },
  },
  dealerConfigUrl:
    "https://dealers.sunway.nl/wp-json/v2/webapp/v1/dealerMeta?api_key=6518fcfb-c603-4e2c-b0b6-b4a8371e948e&dealer_id={{DEALER_ID}}",
};

export default {
  settings,
  getOAthToken() {
    return axios({
      method: "POST",
      crossDomain: true,
      baseURL: "https://identity.sunway.nl",
      url: "core/connect/token",
      data: qs.stringify(settings.oAuth),
    });
  },
  getProductData(url, method, oAuthToken) {
    return axios({
      url: url,
      method: method,
      crossDomain: true,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + oAuthToken,
      },
    });
  },
  selectProduct(productId, oAuthToken) {
    const url =
      settings.serviceUrl + settings.serviceUrlGET.replace("{PID}", productId);

    return this.getProductData(url, "GET", oAuthToken);
  },
  getContentProviderData(url) {
    return axios({
      url: url + `?api_key=${settings.contentProviders.auth.api_key}`,
      method: "GET",
      crossDomain: true,
    });
  },
  getInitialData(url) {
    return axios({
      url: url,
      method: "GET",
      crossDomain: true
    })
  },
  getDealerData(dealerID) {
    const url = settings.dealerConfigUrl.replace("{{DEALER_ID}}", dealerID);
    return axios({
      url: url,
      method: "GET",
      crossDomain: true
    })
  },
  sendMail(data, mailUrl) {
    return axios({
      url: mailUrl,
      method: "POST",
      crossDomain: true,
      data: data
    })
  },
};
