<template>
  <div class="block">
    <div class="block__top">
      <h2 class="block-title" v-if="block.title" v-html="block.title"></h2>
    </div>
    <div class="block__main">
      <ul class="summary-list">
        <li
          v-for="summaryItem in block.summaryItems"
          :key="summaryItem.blockElementId"
          class="list-item"
        >
          <div class="list-item__name">
            {{ summaryItem.title }}
          </div>
          <div class="list-item__value" v-html="summaryItem.value"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductSummary",
  props: {
      block: Object
  }
};
</script>
