const state = () => ({
  modal: {
    isOpen: false,
    image: null,
    video: null,
    html: null,
  },
  productSelectorOpen: false
});

const getters = {
  modal: (state) => state.modal,
  productSelectorOpen: state => state.productSelectorOpen
};

const mutations = {
  openContentModal(state, event) {
    state.modal.image = event.target.dataset.image;
    state.modal.video = event.target.dataset.video;
    state.modal.html = event.target.dataset.html;
    state.modal.isOpen = true;
  },
  closeContentModal(state) {
    state.modal.isOpen = false
  },
  setProductSelector(state, value) {
    state.productSelectorOpen = value
  },
  toggleProductSelector(state) {
    state.productSelectorOpen = !state.productSelectorOpen
  }
};

export default {
  state,
  getters,
  mutations,
};
