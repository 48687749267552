<template>
  <div class="block">
    <div class="block__main">
      <ul :class="['option-list', block.listClass]">
        <li
          v-for="option in block.customOptions"
          :key="option.key"
          :class="['list-item', option.selected ? 'selected' : '']"
        >
          <input
            type="radio"
            :class="option.selected ? 'selected' : ''"
            :checked="option.selected"
            :id="option.key"
            :value="option.value"
            :name="block.name"
            :disabled="isLoading"
            @change="onRoomChange($event, option.key)"
          />
          <label :for="option.key"></label>
          <div class="list-item__image">
            <div class="image-wrapper">
              <img
                :src="option.thumb"
                alt=""
                @load="
                  (event) => {
                    event.target.classList.add('has-loaded');
                  }
                "
              />
            </div>
          </div>
          <div class="list-item__content">
            <h4>
              {{ option.data.title }}
            </h4>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoomSelect",
  props: {
      block: Object,
      isLoading: Boolean
  },
  methods: {
      onRoomChange(event, id) {
          this.$emit("onRoomChange", event, id)
      }
  }
};
</script>
