<template>
  <svg
    class="icon"
    id="a73bd362-af02-40fb-bfc8-4955220923f2"
    data-name="check-mark"
    xmlns="http://www.w3.org/2000/svg"
    width="12.81"
    height="10.33"
    viewBox="0 0 12.81 10.33"
  >
    <g id="40c9a4ff-ad2d-4d31-ab5d-7f80ef20a27a" data-name="check">
      <polygon
        points="5.55 10.33 0 5.7 1.28 4.16 5.26 7.49 11.25 0 12.81 1.25 5.55 10.33"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "CheckmarkIcon",
};
</script>