const state = () => ({
  isLoading: true,
  isLoadingFirstTime: true,
  isUpdatingStep: false,
  currentLoadingtext: "",
  loadingText: "",
  mainImgLoading: true,
});

const getters = {
  isLoading: (state) => state.isLoading,
  isLoadingFirstTime: (state) => state.isLoadingFirstTime,
  isUpdatingStep: (state) => state.isUpdatingStep,
  currentLoadingtext: (state) => state.currentLoadingtext,
  loadingText: (state) => state.loadingText,
  mainImgLoading: (state) => state.mainImgLoading,
};

const actions = {
  rotateLoadingText({ commit }, textStrings) {
    const nrOfItems = textStrings.length;
    let activeItem = 0;
    let intervalTime = Math.floor(Math.random() * 1300) + 600;

    const interval = setInterval(() => {
      if (activeItem < nrOfItems) {
        commit("setCurrentLoadingText", textStrings[activeItem]);
        activeItem++;

        if (Math.random() > 0.5) {
          // lengthen interval time
          intervalTime = intervalTime + (Math.floor(Math.random() * 350) + 150);
        } else {
          // shorten interval time
          intervalTime = intervalTime - (Math.floor(Math.random() * 350) + 150);
        }
      } else {
        clearInterval(interval);
      }
    }, intervalTime);
  },
};

const mutations = {
  setLoading(state, status) {
    state.isLoading = status;
  },
  setLoadingFirstTime(state, status) {
    state.isLoadingFirstTime = status;
  },
  setIsUpdatingStep(state, status) {
    state.isUpdatingStep = status;
  },
  setMainImgLoading(state, status) {
    state.mainImgLoading = status
  },
  setCurrentLoadingText(state, text) {
    state.currentLoadingtext = text;
  },
  clearLoadingText(state) {
      state.currentLoadingtext = ''
  },
  setStepLoadingText(state, value) {
    state.loadingText = value
  },
  clearStepLoadingText(state) {
    state.stepLoadingText = ''
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
