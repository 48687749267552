<template>
  <div>
    <SingleModal
      :showModal="modal.isOpen"
      closeBtn="true"
      @closeModalClickedHandler="closeModalClickedHandler"
      class="js-content-modal"
    >
      <template slot="modal-body">
        <img class="modal__image" v-if="modal.image" :src="modal.image" />
        <div class="modal__html" v-if="modal.html" v-html="modal.html"></div>
        <div class="modal__video" v-if="modal.video">
          <div class="embed-container">
            <iframe
              width="560"
              height="315"
              :src="modal.video + '?rel=0&amp;showinfo=0&autoplay=1'"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </template>
    </SingleModal>
    <SingleModal :showModal="sessionExpired" customClass="modal--session-expired">
      <template slot="modal-header">
        <h1>Sessie verlopen</h1>
      </template>
      <template slot="modal-body">
        <button class="btn btn--1" @click="init">Sessie herstellen</button>
      </template>
    </SingleModal>
  </div>
</template>

<script>
// Vuex store
import { mapGetters } from "vuex";

// Components
import SingleModal from "../ui/SingleModal.vue";

export default {
  name: "Modals",
  components: {
    SingleModal,
  },
  computed: {
    ...mapGetters(["modal", "sessionExpired"]),
  },
  // props: ["modal", "sessionExpired"],
  methods: {
      closeModalClickedHandler() {
          this.$store.commit("closeContentModal")
      },
      init() {
          this.$emit("init")
      }
  }
};
</script>