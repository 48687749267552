<template>
  <svg
    class="icon icon--half-open"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="37"
    height="26"
    viewBox="0 0 37 26"
  >
    <defs>
      <mask
        id="a8858fc2-d657-48e1-97b1-123b482a6643"
        x="0"
        y="12.08"
        width="7.99"
        height="1.83"
        maskUnits="userSpaceOnUse"
      >
        <g data-name="mask-2">
          <polygon
            id="b6d29c2a-76cc-4343-bd27-92607bfac7c7"
            data-name="path-1"
            points="0 12.09 7.99 12.09 7.99 13.91 0 13.91 0 12.09"
            fill="#fff"
            fill-rule="evenodd"
          />
        </g>
      </mask>
    </defs>
    <g id="e59d1c6c-a932-424e-8697-754439e1df36" data-name="Group-30">
      <path
        id="b52e4b60-4305-4b85-8ac6-7b1d7d04cd04"
        data-name="Fill-1"
        d="M18.5,25.69A12.69,12.69,0,1,1,31.27,13,12.74,12.74,0,0,1,18.5,25.69"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="bd510478-1dba-40a6-bd40-66b13d47ed16"
        data-name="Fill-3"
        d="M18.5,0A13,13,0,1,0,31.57,13,13,13,0,0,0,18.5,0m0,.61A12.39,12.39,0,1,1,6,13,12.44,12.44,0,0,1,18.5.61"
        fill-rule="evenodd"
      />
      <path
        id="b78c578b-c712-4a97-96e8-fab8189ab7f2"
        data-name="Fill-5"
        d="M18.5,2.5A10.53,10.53,0,0,0,8,12.08H29A10.53,10.53,0,0,0,18.5,2.5"
        fill-rule="evenodd"
      />
      <path
        id="a12898e6-8a92-42ad-a3f7-2210d098b0f6"
        data-name="Fill-7"
        d="M.61,13.31H8c0-.11,0-.21,0-.31s0-.2,0-.31H.61Z"
        fill-rule="evenodd"
      />
      <path
        id="aed7d5cb-4b9d-43e1-8b36-e603693150ce"
        data-name="Fill-9"
        d="M29.05,13.31h7.34v-.62H29.05c0,.11,0,.21,0,.31s0,.2,0,.31"
        fill-rule="evenodd"
      />
      <path
        id="bfdb61a6-86d2-4d86-a0c2-85447e316b3e"
        data-name="Fill-11"
        d="M8,13.31h21.1c0-.11,0-.21,0-.31s0-.2,0-.31H8c0,.11,0,.21,0,.31s0,.2,0,.31"
        fill-rule="evenodd"
      />
      <g id="bd71e01a-f352-426b-b62a-4a2f156ee431" data-name="Group-15">
        <g mask="url(#a8858fc2-d657-48e1-97b1-123b482a6643)">
          <path
            id="ad416e59-29a2-4b90-af67-a81854986021"
            data-name="Fill-13"
            d="M.31,13.61V12.39H8l0-.31H0v1.84H8l0-.31Z"
            fill="#fff"
            fill-rule="evenodd"
          />
        </g>
      </g>
      <path
        id="f0832ff3-feb0-442e-9389-97eb8c3d9aaf"
        data-name="Fill-16"
        d="M36.39,12.08H29l0,.31h7.65v1.22H29l0,.31h8V12.08Z"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="f806ef59-7ed5-4137-ba94-a25ea9caef1b"
        data-name="Fill-18"
        d="M29,12.39l0-.31H8l0,.31Z"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="b573c9e6-cd69-4ec3-971b-b04d4907c0ff"
        data-name="Fill-20"
        d="M8,13.61l0,.31H29l0-.31Z"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="bda03433-77ea-45f1-9695-7d5b41ccbd7e"
        data-name="Fill-22"
        d="M36.39,12.69v.62H29.05c0,.1,0,.2,0,.3h7.65V12.39H29c0,.1,0,.2,0,.3Z"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="b5e6c9ea-70cf-44a2-ac4a-89195b0dedb8"
        data-name="Fill-24"
        d="M.61,13.31v-.62H8c0-.1,0-.2,0-.3H.31v1.22H8c0-.1,0-.2,0-.3Z"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="f6cf411e-8ca1-4162-85f9-bda6aa7a8c9f"
        data-name="Fill-26"
        d="M8,12.69h21.1c0-.1,0-.2,0-.3H8c0,.1,0,.2,0,.3"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="bb26fc55-e53e-4785-8365-6bfceb4e45ca"
        data-name="Fill-28"
        d="M8,13.31c0,.1,0,.2,0,.3H29c0-.1,0-.2,0-.3Z"
        fill="#fff"
        fill-rule="evenodd"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "HalfOpenIcon",
};
</script>
