<template>
  <div class="open-state">
    <button
      @click.prevent="openStateClicked('open')"
      :class="['open-state__button', state.open ? 'is-active' : false]"
    >
      <OpenIcon />
      <span class="open-state__button-label">Open</span>
    </button>
    <button
      @click.prevent="openStateClicked('half')"
      :class="['open-state__button', state.half ? 'is-active' : false]"
    >
      <HalfOpenIcon />
      <span class="open-state__button-label">Half</span>
    </button>
    <button
      @click.prevent="openStateClicked('closed')"
      :class="['open-state__button', state.closed ? 'is-active' : false]"
    >
     <ClosedShadesIcon />
      <span class="open-state__button-label">Dicht</span>
    </button>
  </div>
</template>

<script>
// Icons
import OpenIcon from "../../icons/openStates/OpenIcon.vue";
import HalfOpenIcon from "../../icons/openStates/HalfOpenIcon.vue";
import ClosedShadesIcon from '../../icons/openStates/ClosedShadesIcon.vue'

export default {
  name: "ShadesOpenState",
  data() {
    return {
      state: {
        open: false,
        half: true,
        closed: false,
      },
    };
  },
  components: {
    OpenIcon,
    HalfOpenIcon,
    ClosedShadesIcon
  },
  methods: {
    openStateClicked(value) {
      if (event.target.nodeName === "BUTTON") {
        event.target.blur();
      } else {
        event.target.closest("button").blur();
      }

      for (const state in this.state) {
        if (state === value) {
          this.state[state] = true;
        } else {
          this.state[state] = false;
        }
      }

      this.$emit("openStateClicked", value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./src/styles/generic/settings";
@import "./src/styles/components/open-state";
</style>
