import { createRouter,createWebHistory  } from 'vue-router'
import Home from './views/Home.vue';
import store from './store'

const router = createRouter({
  history: createWebHistory(),
  base: '/',
  routes: [
    {
      path: '/product/:slug',
      name: 'product',
      component: Home
    },
    {
      path: '/',
      name: 'Home',
      component: Home
    }
  ]
});

router.beforeEach((to, from, next) => {
  store.commit('setSlug', to.params.slug);
  next();
});

export default router;