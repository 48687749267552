<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 1L1 13M1 1l12 12"
      stroke="#555"
      stroke-width="2"
      fill="none"
      fill-rule="evenodd"
      opacity=".332"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "CloseModalIcon",
};
</script>