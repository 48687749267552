const state = () => ({
  productImage: null,
  mainImgBackgroundImage: null,
  mainImgBackgroundColorFirst: null,
  mainImgBackgroundColorSecond: null,
});

const getters = {
  productImage: (state) => state.productImage,
  mainImgBackgroundImage: (state) => state.mainImgBackgroundImage,
  mainImgBackgroundColorFirst: (state) => state.mainImgBackgroundColorFirst,
  mainImgBackgroundColorSecond: (state) => state.mainImgBackgroundColorSecond,
  mainBackground: (state, getters) => {
    if (getters.product && getters.product.showBackgroundImage) {
      if (state.mainImgBackgroundImage) {
        return (
          "background-image: url(" +
          state.mainImgBackgroundImage +
          "); background-size: 15%"
        );
      } else {
        return "";
      }
    } else {
      if (
        state.mainImgBackgroundColorFirst &&
        !state.mainImgBackgroundColorSecond
      ) {
        return "background-color: " + state.mainImgBackgroundColorFirst;
      } else if (
        state.mainImgBackgroundColorFirst &&
        state.mainImgBackgroundColorSecond
      ) {
        const degrees =
          getters.product.rooms.rooms[getters.selectedRoomIndex]
            .multipleFabricDegrees;
        const percentage =
          getters.product.rooms.rooms[getters.selectedRoomIndex]
            .multipleFabricPercentage;
        return (
          "background: linear-gradient(" +
          degrees +
          "deg, " +
          state.mainImgBackgroundColorFirst +
          " " +
          percentage +
          "%, " +
          state.mainImgBackgroundColorSecond +
          " 0% );"
        );
      }
    }
  },
};

const actions = {};

const mutations = {
  setProductImage(state, image) {
    state.productImage = image;
  },
  setMainBackgroundImage(state, image) {
    state.mainImgBackgroundImage = image;
  },
  setMainBackgroundColor(state, { color, number }) {
    if (number === 1) {
      state.mainImgBackgroundColorFirst = color;
    } else if (number === 2) {
      state.mainImgBackgroundColorSecond = color;
    }
  },
  resetMainBackgroundColor(state, number) {
    if (number === 1) {
      state.mainImgBackgroundColorFirst = null;
    }
    if (number === 2) {
      state.mainImgBackgroundColorSecond = null;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
