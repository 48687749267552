<template>
  <div :class="['block', !block.isValid ? 'block--error' : '']">
    <div class="block__main block__main--boolean custom-form">
      <input
        type="checkbox"
        :id="block.id"
        :checked="block.value"
        :value="!block.value"
        :name="block.name"
        :disabled="isLoading"
        @change="onInputChange($event, block)"
      />
      <label :for="block.id">{{ block.title }}</label>
      <button
        v-if="block.description"
        @click="openContentModal"
        class="btn-help"
        :data-html="block.description"
      ></button>
    </div>
    <div class="block__messages" v-if="block.messages.length > 0">
      <ul>
        <li
          v-for="message in block.messages"
          :class="message.className"
          :key="message.blockElementId"
        >
          {{ message.text }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxOption",
  props: {
      block: Object,
      isLoading: Boolean
  },
  methods: {
      onInputChange(event, block) {
          this.$emit("onInputChange", event, block)
      },
      openContentModal(event) {
          this.$store.commit("openContentModal", event)
      } 
  }
};
</script>