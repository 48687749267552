<template>
  <div class="background">
    <transition name="fade" duration="800">
      <div v-if="productData" class="background__image" :style="mainBackground">
        <img @load="mainImgLoaded" :src="productImage" />
      </div>
    </transition>
    <transition name="fade" duration="800">
      <div class="loader" v-show="mainImgLoading">
        <div class="loader-animation"></div>
      </div>
    </transition>
  </div>
</template>

<script>
// Vuex store
import { mapGetters } from "vuex";

export default {
  name: "ProductRender",
  props: {
    productData: Object,
  },
  methods: {
    mainImgLoaded() {
      this.$store.commit("setMainImgLoading", false);
    },
  },
  computed: {
    ...mapGetters([
      "mainImgLoading",
      "product",
      "productImage",
      "mainImgBackgroundImage",
      "mainImgBackgroundColorFirst",
      "mainImgBackgroundColorSecond",
      "mainBackground",
    ]),
  },
};
</script>