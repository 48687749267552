<template>
  <div class="mailresult">
    <h2>{{ mailResponseContent.title }}</h2>
    <div
      v-if="mailResponseContent.message"
      v-html="mailResponseContent.message"
    ></div>
    <button
      :disabled="isLoading"
      @click="reset"
      class="btn btn--1"
      v-if="mailResponseContent.success"
    >
      {{ mailResponseContent.btnText }}
    </button>
    <button
      :disabled="isLoading"
      @click="resetMailResponse(false)"
      class="btn btn--2"
      v-else
    >
      {{ mailResponseContent.btnText }}
    </button>
  </div>
</template>

<script>
// Vue / Vuex
import { mapGetters } from "vuex";

export default {
  name: "EmailResponse",
  computed: {
    ...mapGetters(["isLoading", "mailResponseContent"]),
  },
  methods: {
    resetMailResponse(value) {
      this.$store.commit("setMailResponse", value);
    },
    reset() {
      this.$emit("reset");
    },
  },
};
</script>