<template>
   <svg
        class="icon icon--closed"
        id="a97f0769-2362-44b8-9d69-0a4d5d0342c4"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="37"
        height="26"
        viewBox="0 0 37 26"
      >
        <defs>
          <mask
            id="afeaf8fc-c89e-4db0-a5eb-2388a2c7a058"
            x="0"
            y="0"
            width="37"
            height="26"
            maskUnits="userSpaceOnUse"
          >
            <g id="a36d9135-1155-4794-abf2-956d803d7c37" data-name="mask-2">
              <polygon
                id="e87e8b78-6a4e-4f56-a413-5b7857d1f4f4"
                data-name="path-1"
                points="0 26 37 26 37 0 0 0 0 26"
                fill="#fff"
                fill-rule="evenodd"
              />
            </g>
          </mask>
        </defs>
        <g id="bb64b5d8-1965-48ac-89a5-9f72a02c5a83" data-name="Group-10">
          <path
            id="b944e7d6-b10b-4ddb-82ca-964069f997ef"
            data-name="Fill-1"
            d="M18.5,25.69A12.69,12.69,0,1,1,31.27,13,12.74,12.74,0,0,1,18.5,25.69"
            fill="#fff"
            fill-rule="evenodd"
          />
          <path
            id="fc9ed3f3-d5c6-4e7a-82be-ee8fa74915ca"
            data-name="Fill-3"
            d="M18.5,0A13,13,0,1,0,31.57,13,13,13,0,0,0,18.5,0m0,.61A12.39,12.39,0,1,1,6,13,12.44,12.44,0,0,1,18.5.61"
            fill-rule="evenodd"
          />
          <path
            id="b2ae712f-6470-445f-bf92-904186177b96"
            data-name="Fill-5"
            d="M29.06,13A10.56,10.56,0,1,1,18.5,2.5,10.53,10.53,0,0,1,29.06,13"
            fill-rule="evenodd"
          />
          <g mask="url(#afeaf8fc-c89e-4db0-a5eb-2388a2c7a058)">
            <polygon
              id="a9b3b930-5692-4a4f-a55b-58866d194ace"
              data-name="Fill-7"
              points="0.31 13.61 36.69 13.61 36.69 12.39 0.31 12.39 0.31 13.61"
              fill-rule="evenodd"
            />
          </g>
          <g mask="url(#afeaf8fc-c89e-4db0-a5eb-2388a2c7a058)">
            <path
              id="a4fe93c4-43db-4429-9b0a-53388267de25"
              data-name="Fill-9"
              d="M37,12.08H0v1.84H37V12.08ZM.61,13.31H36.39v-.62H.61Z"
              fill="#fff"
              fill-rule="evenodd"
            />
          </g>
        </g>
      </svg>
</template>

<script>
export default {
    name: "ClosedShadesIcon"
}
</script>
