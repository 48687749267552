const state = () => ({
  staticSteps: false,
  currentStep: 0,
  lastStep: 0,
  initialProductData: null,
  staticContent: {},
  slug: "",
  selectedRoomIndex: 0,
  selectedRoomColor: null,
  subProducts: null
});

const getters = {
  staticSteps: (state) => state.staticSteps,
  currentStep: (state) => state.currentStep,
  lastStep: (state) => state.lastStep,
  staticContent: (state) => state.staticContent,
  initialProductData: (state) => state.initialProductData,
  slug: (state) => state.slug,
  selectedRoomIndex: (state) => state.selectedRoomIndex,
  selectedRoomColor: (state) => state.selectedRoomColor,
  subProducts: (state) => state.subProducts,
  product: (state) => {
    if (state.slug && state.initialProductData) {
      return state.initialProductData.find(
        (product) => product.slug === state.slug
      );
    } else {
      return null;
    }
  },
  topLevelProducts: (state) => {
    return state.initialProductData.filter((product) => !product.parent);
  },
  subLevelProducts: (state) => {
    return state.initialProductData.filter((product) => product.parent);
  },

  // URL Variables:
  roomSet: (state, getters) => {
    return getters.product.rooms.rooms[state.selectedRoomIndex].roomSet;
  },
};

const actions = {
  changeMainImage({ getters, state, commit }, url) {
    commit("setMainImgLoading", true, { root: true }); // mutation in loadingState store

    let roomImage;
    if (!getters.product.roomURL) {
      // If no designspace product
      roomImage = getters.product.rooms.rooms[
        state.selectedRoomIndex
      ].colors.find(
        (currentColor) => currentColor.color === state.selectedRoomColor
      ).mainImage;
    } else {
      // If designspace product
      roomImage = url;
    }

    commit("setProductImage", roomImage, {root: true});
  },
};

const mutations = {
  setSlug(state, slug) {
    state.slug = slug;
  },
  setStaticContent(state, data) {
    state.staticContent = data;
  },
  setStaticContentLoadingText(state, loadingText) {
    state.staticContent.loadingTxts = loadingText;
    state.staticContent.loading = loadingText[0];
  },
  setInitialProductData(state, data) {
    state.initialProductData = data;
  },
  setProductData(state, data) {
    state.productData = data;
  },
  setContent(state, data) {
    state.content = data;
  },
  setContentBlocks(state, data) {
    state.content.blocks = data;
  },
  setSavedContentBlocks(state, data) {
    state.content.savedBlocks = data;
  },
  pushContentBlocks(state, blockElement) {
    state.content.blocks.push(blockElement);
  },
  pushSavedContentBlocks(state, blockElement) {
    state.content.savedBlocks.push(blockElement);
  },
  setSelectedRoomIndex(state, value) {
    state.selectedRoomIndex = value;
  },
  setSelectedRoomColor(state, value) {
    state.selectedRoomColor = value;
  },
  showSubProducts(state, parentId) {
    state.subProducts = state.initialProductData.filter(
      (product) => product.parent === parentId
    );
  },
  hideSubProducts(state) {
    state.subProducts = null
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
